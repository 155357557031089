const resetButton = document.getElementById("resetButton") as HTMLButtonElement;
const consoleStartButton = document.getElementById("consoleStartButton") as HTMLButtonElement;
const terminal = document.getElementById("terminal");

//test constants for writing to esp
const wifiCredentialsButton = document.getElementById("wifiCredentialsButton") as HTMLButtonElement;
const scanButton = document.getElementById("scanButton") as HTMLButtonElement;
const showPasswordButton = document.getElementById("showButton") as HTMLButtonElement;
const hidePasswordButton = document.getElementById("hideButton") as HTMLButtonElement;
const cartridgeButton = document.getElementById("cartridgeButton") as HTMLButtonElement;
const hardwareButton = document.getElementById("hardwareButton") as HTMLButtonElement;

// This is a frontend example of Esptool-JS using local bundle file
// To optimize use a CDN hosted version like
// https://unpkg.com/esptool-js@0.2.0/bundle.js
import {Transport} from "../lib";

declare let Terminal; // Terminal is imported in HTML script

const term = new Terminal({cols: 80, rows: 26});
term.open(terminal);

let device = null;
let transport: Transport;

wifiCredentialsButton.onclick = async () => {
    const ssid = document.getElementById('ssid') as HTMLInputElement
    const password = document.getElementById('password') as HTMLInputElement
    const uuid = document.getElementById('uuid') as HTMLInputElement

    let data = {
        subject: 2,
        ssid: ssid.value,
        password: password.value,
        uuid: uuid.value,
    };

    const message = new TextEncoder().encode(JSON.stringify(data))
    await transport.write(message)
}

cartridgeButton.onclick = async () => {
    const cartridge = document.getElementById('cartridge') as HTMLInputElement
    let data = {
        subject: 3,
        volume: cartridge.value,
    };

    const message = new TextEncoder().encode(JSON.stringify(data))
    await transport.write(message)
}

hardwareButton.onclick = async () => {
    const hardware = document.getElementById('hardware') as HTMLInputElement
    const status = document.getElementById('status') as HTMLInputElement
    const debug = document.getElementById('debug') as HTMLInputElement
    let data = {
        subject: 4,
        type: hardware.value,
        status: status.value,
        debug_message: debug.value
    };

    const message = new TextEncoder().encode(JSON.stringify(data))
    await transport.write(message)
}


scanButton.onclick = async () => {
    let data = {
        subject: 1
    };

    const message = new TextEncoder().encode(JSON.stringify(data))
    await transport.write(message)
}

resetButton.onclick = async () => {
    term.clear();
    // Additional cleanup code if needed
    await transport.disconnect();

    // Reset device and transport references
    device = null;
    transport = null;
};

showPasswordButton.onclick =  () => {
    const passwordInput  = document.getElementById('password') as HTMLInputElement
    passwordInput.type = 'text';
    showPasswordButton.style.display = 'none';
    hidePasswordButton.style.display = 'block';
}

hidePasswordButton.onclick =  () => {
    const passwordInput  = document.getElementById('password') as HTMLInputElement
    passwordInput.type = 'password';
    showPasswordButton.style.display = 'block';
    hidePasswordButton.style.display = 'none';
}

let isConsoleClosed = false;
consoleStartButton.onclick = async () => {
    if (device === null) {
        device = await navigator.serial.requestPort({});
        transport = new Transport(device);
    }
    await transport.connect();

    term.write("Connected to ESP32. Ready to receive messages.");

    isConsoleClosed = false;

    while (!isConsoleClosed) {
        const val = await transport.rawRead();
        if (typeof val !== "undefined") {
            term.write(val);
        } else {
            break;
        }
    }

    console.log("quitting console");
};
